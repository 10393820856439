/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Paper, Typography } from "@mui/material";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import OpenFilesButton from "./OpenFilesButton/OpenFilesButton";
import { DetailsDataType } from "../../../types";
import { useStyles } from "../../../styles";
import {
  tableDetailsLoading,
  tableLoading,
  tableName,
} from "../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import RectangleLoader from "../../../../../shared/components/loaders/RectangleLoader";

interface IdetailsCardProps {
  children: ReactNode;
  cardTitle: string;
  collapsable?: boolean;
  inlineSubtitle?: string;
  index?: number;
  firstCollapsableCard?: boolean;
  detailsData?: DetailsDataType;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  contentLoading?: boolean;
  moreActions?: ReactNode;
}

const DetailsCard = ({
  children,
  cardTitle,
  collapsable,
  inlineSubtitle,
  index,
  firstCollapsableCard,
  detailsData,
  setIsLoading,
  contentLoading = false,
  moreActions,
}: IdetailsCardProps) => {
  const { colors } = useContext(ThemeContext);

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);
  const resourceName = useRecoilValue(tableName);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    detailsPaperCard,
    firstCollapsablePaperCard,
    detailsPaperCardContent,
    paperCardTitle,
    collapsableTitleArea,
    collapseArrow,
  } = useStyles();

  const displayLoader = isDetailsLoading || isTableLoading || contentLoading;

  const transitionTiming = "0.2s";
  const arrowRotationAngle = isOpen ? 0 : "-0.5turn";
  const minCardHeight = collapsable ? "56px" : "172px";
  const collapsableCardContent = isOpen ? children : <></>;
  const collapsableCardMaxHeight = isOpen ? "500px" : 0;
  const collapsableCardOpacity = isOpen ? 1 : 0;
  const paperCardCSS = firstCollapsableCard
    ? firstCollapsablePaperCard
    : detailsPaperCard;
  const isFirstItemAllowedToCollapse = !displayLoader && index === 0;

  useEffect(() => {
    // reset isOpen state on loader to avoid state cache
    displayLoader && setIsOpen(false);
    isFirstItemAllowedToCollapse && setIsOpen(true);
    setIsLoading && setIsLoading(displayLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, setIsLoading, displayLoader]);

  const handleToggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const renderExpandIcon = () => {
    return (
      <div css={css(collapseArrow)} onClick={handleToggleCollapse}>
        {
          <ExpandLessIcon
            data-testid="expand-less-icon"
            css={css({
              transition: `all ${transitionTiming} ease`,
              transform: `rotate(${arrowRotationAngle})`,
            })}
          />
        }
      </div>
    );
  };

  const renderChildren = () =>
    displayLoader ? (
      <RectangleLoader
        height="16px"
        width="129px"
        testId={`details-card-content-loader-${cardTitle}`}
      />
    ) : (
      children
    );

  const renderInlineSubtitle = () => {
    return (
      <Typography
        variant="body2"
        sx={{
          color: colors.textSecondary,
          marginRight: "16px",
          cursor: "default",
        }}
      >
        {inlineSubtitle}
      </Typography>
    );
  };

  const renderDetailsCard = () => (
    <Paper
      data-testid="details-card"
      css={css([
        paperCardCSS,
        {
          border: `1px solid ${colors.gray200}`,
          minHeight: minCardHeight,
        },
      ])}
    >
      <div css={css(detailsPaperCardContent)}>
        <div css={css(paperCardTitle)}>
          {displayLoader && (
            <RectangleLoader
              height="16px"
              width="216px"
              testId={`details-card-title-loader-${cardTitle}`}
            />
          )}

          {!displayLoader && (
            <div css={css(collapsableTitleArea)}>
              <Typography variant="body2Bold">{cardTitle}</Typography>
              <div css={css(collapseArrow)}>
                {Boolean(inlineSubtitle) && renderInlineSubtitle()}
                {collapsable && renderExpandIcon()}
              </div>

              {moreActions || (
                <OpenFilesButton
                  detailsData={detailsData}
                  resourceName={resourceName}
                />
              )}
            </div>
          )}
        </div>

        {collapsable && (
          <div
            css={css({
              maxHeight: collapsableCardMaxHeight,
              opacity: collapsableCardOpacity,
              transition: `all ease-in-out ${transitionTiming}`,
            })}
          >
            {collapsableCardContent}
          </div>
        )}

        {!collapsable && renderChildren()}
      </div>
    </Paper>
  );

  return displayLoader && collapsable ? <></> : renderDetailsCard();
};

export default DetailsCard;
