import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { EditCompanyPayloadType } from "../../../../types";
import InputField from "../../../../../InputField/InputField";
import {
  CompaniesHierarchyDataDto,
  inputFieldMarginBottom,
} from "../../../../../../shared";
import useCompaniesFormInputs from "../../../../../../shared/hooks/useCompaniesFormInputs/useCompaniesFormInputs";

interface IEditCompanyDialogContentProps {
  rowData: CompaniesHierarchyDataDto;
  setPayload: Dispatch<SetStateAction<EditCompanyPayloadType>>;
  setIsReadyToConfirm: Dispatch<SetStateAction<boolean | "" | null>>;
}

const EditCompanyDialogContent = ({
  rowData,
  setPayload,
  setIsReadyToConfirm,
}: IEditCompanyDialogContentProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(rowData.name);
  const [description, setDescription] = useState<string>(rowData.comment ?? "");
  const [nameInputError, setNameInputError] = useState<string | null>(null);
  const [descriptionInputError, setDescriptionInputError] = useState<
    string | null
  >(null);
  const [autoAddNewDrivers, setAutoAddNewDrivers] = useState<boolean>(
    rowData.automaticDriverRegistrationEnabled
  );

  const {
    validateNameLength,
    handleNameChangeValue,
    validateDescriptionLength,
    handleChangeDescription,
    isNameValid,
    isDescriptionValid,
    generateCompanySettings,
  } = useCompaniesFormInputs({
    setNameInputError,
    setName,
    setDescription,
    setDescriptionInputError,
  });

  const isPayloadReadyToConfirm =
    isNameValid(name) && isDescriptionValid(description);

  useEffect(() => {
    setPayload({
      id: rowData.id,
      name: name.trim(),
      comment: description.trim(),
      automaticDriverRegistrationEnabled: autoAddNewDrivers,
    });
    setIsReadyToConfirm(isPayloadReadyToConfirm);
  }, [
    description,
    isPayloadReadyToConfirm,
    name,
    rowData,
    setIsReadyToConfirm,
    setPayload,
    autoAddNewDrivers,
  ]);

  const handleToggleAddNewDriver = () => {
    setAutoAddNewDrivers(!autoAddNewDrivers);
  };

  const companySettings = generateCompanySettings(
    handleToggleAddNewDriver,
    autoAddNewDrivers
  );

  return (
    <>
      {rowData.parentCompanyId !== null &&
        rowData.parentCompanyName !== null && (
          <InputField
            id={uuidv4()}
            size="medium"
            data-testid="parent-company-name"
            fullWidth
            readOnly
            labelLeft={t("Dialog##parent company")}
            placeholder={rowData.parentCompanyName as string}
            customStyle={inputFieldMarginBottom}
          />
        )}

      <InputField
        id={uuidv4()}
        testId="company-name-input-field"
        value={name}
        size="medium"
        fullWidth
        required
        errorText={isNameValid(name) ? "" : nameInputError}
        onBlur={validateNameLength}
        onChange={handleNameChangeValue}
        labelLeft={t("Dialog##company name")}
        placeholder={t("Dialog##company name placeholder")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="company-description-input-field"
        value={description}
        size="medium"
        fullWidth
        errorText={isDescriptionValid(description) ? "" : descriptionInputError}
        onBlur={validateDescriptionLength}
        onChange={handleChangeDescription}
        labelLeft={t("Dialog##description")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />

      {companySettings}
    </>
  );
};

export default EditCompanyDialogContent;
