/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  SwitchProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode, useContext } from "react";

import {
  generateCustomSwitchStyle,
  generateEndItemMargin,
  generateStartItemMargin,
  startEnditemsAlignment,
  toggleSwitchLabelStyles,
} from "./styleFunctions";
import { TOGGLE_SWITCH_LABEL_PLACEMENT, TOGGLE_SWITCH_SIZES } from "./types";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface ToggleSwitchProps {
  size: TOGGLE_SWITCH_SIZES;
  labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT;
  label?: string;
  startItem?: ReactNode;
  endItem?: ReactNode;
}

const ToggleSwitch = ({
  size,
  labelPlacement,
  label,
  startItem,
  endItem,
  ...props
}: ToggleSwitchProps & Omit<SwitchProps, "size">) => {
  const { colors } = useContext(ThemeContext);

  const CustomSwitch = styled(Switch)(({ theme }) => {
    return generateCustomSwitchStyle({
      size,
      theme,
      colors,
      labelPlacement,
    });
  });

  return (
    <div
      data-testid="toggle-switch-component"
      css={css({ display: "flex", justifyContent: "center" })}
    >
      <Stack direction="row" alignItems="center" margin="0" spacing="0">
        {startItem && (
          <div
            css={css(startEnditemsAlignment, {
              marginRight: generateStartItemMargin(label, labelPlacement),
            })}
          >
            {startItem}
          </div>
        )}

        <FormGroup>
          <FormControlLabel
            control={<CustomSwitch data-testid="cutom-switch" {...props} />}
            label={label}
            labelPlacement={labelPlacement}
            sx={toggleSwitchLabelStyles(
              colors,
              labelPlacement,
              endItem,
              startItem,
              label
            )}
          />
        </FormGroup>

        {endItem && (
          <div
            css={css(startEnditemsAlignment, {
              marginLeft: generateEndItemMargin(label, labelPlacement),
            })}
          >
            {endItem}
          </div>
        )}
      </Stack>
    </div>
  );
};

export default ToggleSwitch;
