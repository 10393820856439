import Checkbox from "../../../CheckBox/Checkbox";
import { Filter } from "../../../../shared";

interface IFilterOptionListProps {
  props: any;
  option: Filter;
  optionName: string;
  isTableLoading: boolean;
  selected: boolean;
  selectedItem: Filter | null;
}

const FilterOptionList = ({
  option,
  optionName,
  isTableLoading,
  selected,
  selectedItem,
  props,
}: IFilterOptionListProps) => {
  return (
    <li
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
        opacity: isTableLoading && selectedItem?.id !== option.id ? 0.4 : 1,
      }}
    >
      {optionName}

      <Checkbox checked={selected} />
    </li>
  );
};

export default FilterOptionList;
