/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";

import { useStyles } from "./styles";

import InfoIconWithTooltip from "../IconsWithTooltips/InfoIconWithTooltip/InfoIconWithTooltip";
import Separator from "../Separator/Separator";
import { ToggleSwitch, TOGGLE_SWITCH_SIZES } from "../ToggleSwitch";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface ToggleItemWithTooltipProps {
  title: string;
  tooltipText?: string;
  onToggle: any;
  isChecked: boolean;
  isDisabled?: boolean;
  index?: number;
  totalItemsCount?: number;
  showSeparator?: boolean;
}

const ToggleItemWithTooltip = ({
  title,
  tooltipText,
  onToggle,
  isChecked,
  isDisabled,
  index = 0,
  totalItemsCount = 1,
  showSeparator,
}: ToggleItemWithTooltipProps) => {
  const { colors } = useContext(ThemeContext);

  const { toggleItemContainer, toggleItemTitleBox } = useStyles(
    isDisabled,
    showSeparator
  );

  const displaySeparator = showSeparator ?? index < totalItemsCount - 1;

  return (
    <>
      <div css={css(toggleItemContainer)}>
        <div css={css(toggleItemTitleBox)}>
          <Typography variant="body2">{title}</Typography>
          {/* empty space: */}
          <Typography variant="body2">&nbsp;</Typography>
          {tooltipText && (
            <InfoIconWithTooltip
              title={tooltipText}
              iconTestId={`${title}-info icon`}
            />
          )}
        </div>

        <ToggleSwitch
          id={`${title}_toggle_switch`}
          size={TOGGLE_SWITCH_SIZES.Medium}
          defaultChecked={!isDisabled ? isChecked : undefined}
          disabled={isDisabled}
          onChange={onToggle}
        />
      </div>
      {displaySeparator && <Separator color={colors.gray200} />}
    </>
  );
};

export default ToggleItemWithTooltip;
