/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, SyntheticEvent, ReactNode } from "react";
import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Typography } from "@mui/material";

import { DialogWidth } from "./types/enums";
import Button from "../Button/Button";
import { useMediaQueries } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface DialogProps {
  title: string | ReactNode;
  actions: any;
  children?: any;
  description?: string | ReactNode;
  isTitleSeparator?: boolean;
  isActionsSeparator?: boolean;
  extendToMaxWidth?: boolean;
  close: any;
  submit?: () => void;
  width?: string | number;
  noValidate?: boolean;
  customPadding?: string;
  maxHeight?: string;
  wordBreakTitle?: string;
  customContentOverflow?: any;
  showXButton?: boolean;
}

export type CombinedDialogProps = DialogProps & MuiDialogProps;

const Dialog = ({
  title,
  actions,
  children,
  description,
  isTitleSeparator,
  isActionsSeparator,
  extendToMaxWidth,
  close,
  submit,
  width,
  noValidate,
  customPadding,
  maxHeight,
  wordBreakTitle,
  customContentOverflow,
  showXButton = true,
  ...props
}: CombinedDialogProps) => {
  const { colors } = useContext(ThemeContext);

  const { toMd } = useMediaQueries();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    submit?.();
  };

  const dialogWidth = width ?? "unset";
  const maxDialogWidthFromMd = width ?? DialogWidth.MaxWidth;

  return (
    <MuiDialog
      {...props}
      onClose={close}
      fullScreen={toMd}
      sx={{
        "& .MuiDialog-paper": {
          width: extendToMaxWidth ? "100%" : dialogWidth,
          maxWidth: toMd ? "unset" : maxDialogWidthFromMd,
          minWidth: toMd ? "unset" : DialogWidth.MinWidth,
          borderRadius: toMd ? "0" : "12px",
          overflowY: "hidden",
          maxHeight: toMd ? "unset" : maxHeight,
        },
      }}
    >
      {close && showXButton && (
        <Button
          variant={"iconOnly"}
          size="small"
          color="white"
          icon={<CloseIcon onClick={close} />}
          css={css({
            position: "absolute",
            top: "4px",
            right: "4px",
          })}
        />
      )}
      <div
        css={css({
          padding: customPadding ?? "24px 24px 16px 24px",
          borderBottom: isTitleSeparator
            ? `1px solid ${colors.gray300}`
            : "none",
        })}
      >
        <Typography
          variant="h5"
          sx={{
            marginRight: "32px",
            display: "block",
            color: colors.textDark,
            wordBreak: wordBreakTitle ?? "normal",
          }}
        >
          {title}
        </Typography>

        {description && (
          <Typography
            variant="body2"
            sx={{
              marginTop: "8px",
              display: "block",
              color: colors.textDark,
            }}
          >
            {description}
          </Typography>
        )}
      </div>

      <form
        onSubmit={handleSubmit}
        noValidate={noValidate}
        css={css({
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
        })}
      >
        {children && (
          <div
            css={css({
              flex: "1",
              overflowY: customContentOverflow ?? "auto",
              padding: customPadding ? "16px" : "16px 24px",
            })}
          >
            {children}
          </div>
        )}

        <div
          css={css({
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr",
            padding: "12px 16px 16px",
            columnGap: "12px",
            rowGap: "12px",
            borderTop: isActionsSeparator
              ? `1px solid ${colors.gray300}`
              : "none",
          })}
        >
          {actions}
        </div>
      </form>
    </MuiDialog>
  );
};

export default Dialog;
