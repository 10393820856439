import { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { DetailsDataType } from "../../../../types";

import Dialog from "../../../../../Dialog/Dialog";

import {
  getSelectedTableItemFromLocalStorage,
  ROUTES,
} from "../../../../../../shared";
import { ApiResources } from "../../../../../../api/resources";

interface DownloadStatusDialogProps {
  open: boolean;
  onClose: () => void;
  detailsData: DetailsDataType;
  webSocketsAccessToken: string | null;
}

const DownloadStatusDialog = ({
  open,
  onClose,
  detailsData,
  webSocketsAccessToken,
}: DownloadStatusDialogProps) => {
  const [socketUrl, setSocketUrl] = useState<any>(null);

  const { lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  const connectToWebSockets = useCallback(() => {
    getWebSocket();
  }, [getWebSocket]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (
      webSocketsAccessToken &&
      detailsData?.id === getSelectedTableItemFromLocalStorage(ROUTES.Vehicles)
    ) {
      setSocketUrl(
        `${process.env.REACT_APP_BASE_URL}${ApiResources.VehiclesLiveStatus}?accessToken=${webSocketsAccessToken}&VehicleId=${detailsData?.id}`
      );
    }
  }, [detailsData?.id, webSocketsAccessToken]);

  useEffect(() => {
    if (
      socketUrl &&
      detailsData?.id === getSelectedTableItemFromLocalStorage(ROUTES.Vehicles)
    ) {
      connectToWebSockets();
      lastJsonMessage &&
        console.log(
          "from downloadStatusDialog",
          `${connectionStatus} to ${socketUrl}`,
          lastJsonMessage
        );
    } else {
      setSocketUrl(null);
    }
  }, [
    connectionStatus,
    detailsData?.id,
    lastJsonMessage,
    socketUrl,
    connectToWebSockets,
  ]);

  return (
    <Dialog title="Download status" open={open} close={onClose} actions={<></>}>
      <div></div>
    </Dialog>
  );
};

export default DownloadStatusDialog;
