import DownloadStatusDialog from "../DownloadStatusDialog/DownloadStatusDialog";

import { DetailsDataType } from "../../../../types";

import { useDialog } from "../../../../../PageHeader/actions/hooks/useDialog";

import { VEHICLES_ACTIONS } from "../../../../../../shared";
import MoreMenuChoice from "../../../../../../shared/components/sharedListChoices/MoreMenuChoice/MoreMenuChoice";

interface IDownloadStatusButtonProps {
  ondDownloadStatusClick: () => void;
  onActionsClose: () => void;
  detailsData: DetailsDataType;
  webSocketsAccessToken: string | null;
}

const DownloadStatusButton = ({
  ondDownloadStatusClick,
  onActionsClose,
  detailsData,
  webSocketsAccessToken,
}: IDownloadStatusButtonProps) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const handleDownloadStatusClick = () => {
    ondDownloadStatusClick();
    openDialog(VEHICLES_ACTIONS.DownloadStatus);
  };

  const handleCloseDownloadStatusDialog = () => {
    closeDialog(VEHICLES_ACTIONS.DownloadStatus);
    onActionsClose();
  };

  return (
    <>
      <MoreMenuChoice
        action={VEHICLES_ACTIONS.DownloadStatus}
        onButtonClick={handleDownloadStatusClick}
      />

      <DownloadStatusDialog
        open={isDialogOpen[VEHICLES_ACTIONS.DownloadStatus]}
        onClose={handleCloseDownloadStatusDialog}
        detailsData={detailsData}
        webSocketsAccessToken={webSocketsAccessToken}
      />
    </>
  );
};

export default DownloadStatusButton;
