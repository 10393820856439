/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import DetailsCard from "../DetailsCard";
import DetailsCardItemText from "../DetailsCardItemText";
import DetailsCardItemTitle from "../DetailsCardItemTitle";
import { useStyles } from "../../../../styles";
import { IGeneralDetailsProps } from "../../../../../../shared";

const CompaniesGeneralDetails = ({ detailsData }: IGeneralDetailsProps) => {
  const { t } = useTranslation();
  const { paperCardItem, paperCardItemAlignStart } = useStyles();

  const getCorrectString = (
    data: string | number | null | undefined,
    translationText: string
  ) => {
    return data === null
      ? null
      : `(${data} ${t(translationText).toLowerCase()})`;
  };

  return (
    <DetailsCard cardTitle={t("Details##general")}>
      <div css={css([paperCardItem, paperCardItemAlignStart])}>
        <DetailsCardItemTitle title={t("Details##parent company")} />
        <DetailsCardItemText textToRender={detailsData?.parentCompanyName} />
      </div>

      {detailsData?.parentCompanyId === null && (
        <div css={css(paperCardItem)}>
          <DetailsCardItemTitle title={t("Details##credits")} />
          <DetailsCardItemText
            textToRender={detailsData?.creditsCount}
            titleInlineAddition={getCorrectString(
              detailsData?.reservedCreditsCount,
              "Details##reserved"
            )}
          />
        </div>
      )}
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##vehicles")} />
        <DetailsCardItemText
          textToRender={detailsData?.allVehiclesCount}
          titleInlineAddition={getCorrectString(
            detailsData?.disabledVehiclesCount,
            "Details##deactivated"
          )}
        />
      </div>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##drivers")} />
        <DetailsCardItemText textToRender={detailsData?.driversCount} />
      </div>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##users")} />
        <DetailsCardItemText textToRender={detailsData?.usersCount} />
      </div>
    </DetailsCard>
  );
};

export default CompaniesGeneralDetails;
