/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { useContext } from "react";

import { ThemeContext } from "../context/theme/ThemeContextProvider";

const GlobalStyles = () => {
  const { colors } = useContext(ThemeContext);

  return (
    <Global
      styles={{
        html: {
          height: "100%",
          background: colors.white,
        },

        body: {
          height: "100%",
          margin: "0",
          fontFamily: '"Open Sans", sans-serif',
          overflowY: "hidden",
          overflowX: "hidden",

          "header + main": {
            width: "calc(100vw - 18px)",
          },

          "@media (max-width: 959px)": {
            "header + main, #root > footer": {
              width: "100%",
            },
          },
        },

        div: {
          scrollbarWidth: "thin",
          scrollbarColor: `${colors.blue200} transparent !important`,
        },

        "#root": {
          minHeight: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          scrollbarColor: `${colors.blue200} transparent !important`,
        },

        ".scrollable": {
          overflow: "auto",
          scrollbarColor: `${colors.blue200} transparent !important`,
        },

        "main, .remaining-height": {
          display: "flex",
          flexDirection: "column",
          flex: "1",
        },

        "h1, h2, h3, h4, h5, h6": {
          margin: "0",
          fontWeight: "unset",
        },

        ul: {
          margin: "0",
          padding: "0",
        },

        li: {
          listStyle: "none",
        },

        a: {
          textDecoration: "none",
          color: "inherit",
          WebkitTapHighlightColor: "transparent",
        },

        button: {
          ariaHidden: {
            pointerEvents: "auto",
            visibility: "visible",
          },
          inert: {
            pointerEvents: "auto",
            visibility: "visible",
          },
        },

        "*": {
          "&::-webkit-scrollbar": {
            width: "18px",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.blue200,
            borderRadius: "100px",
            border: `5px solid ${colors.white}`,
            minHeight: "60px",
          },

          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: colors.blue400,
          },
        },

        ".react-joyride__spotlight": {
          zIndex: 1,
          backgroundColor: "transparent",
          pointerEvents: "auto",
          "&:after, &:before": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "100%",
            borderRadius: "4px",
          },

          "&:before": {
            width: "100%",
            zIndex: -1,
            animation: "tutorialPulse 2s linear infinite",
          },

          "@keyframes tutorialPulse": {
            "0%": {
              boxShadow: `0 0 0 0 rgba(166,200,255,0.7),  0 0 0 0 rgba(166,200,255,0.7)`,
            },

            "40%": {
              boxShadow: `0 0 0 25px rgba(166,200,255,0.0),  0 0 0 0 rgba(166,200,255,0.7)`,
            },

            "80%": {
              boxShadow: `0 0 0 25px rgba(166,200,255,0.0),  0 0 0 15px rgba(166,200,255,0)`,
            },
            "100%": {
              boxShadow: `0 0 0 0 rgba(166,200,255,0.0),  0 0 0 15px rgba(166,200,255,0)`,
            },
          },
        },
      }}
    />
  );
};

export default GlobalStyles;
